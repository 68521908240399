import { Carousel } from "bootstrap"
import { Navigator, footer } from "../components/navigations";
import { Calculator } from "../components/poonmix";
import { UI } from "./ui";
import { Concrete } from "./product";
import { Blog } from "./blog";

import $ from 'jquery';

import "../../css/homes/homes.scss";

document.addEventListener('DOMContentLoaded',() => {

    const ui = new UI();
    const concrete = new Concrete();
    const calculator = new Calculator();
    const blog = new Blog();
    
    ui.showMainBanner('#banner-carousel')
    const carouselBanner = document.querySelector('#banner-ad')

    const carousel = new Carousel('#banner-carousel', {
      interval: 2000,
      touch: false,
      ride:true
    })
    
    blog.getBlog().then((blogs)=>{
        blog.showBlog(blogs);
        const carouselBlog = new Carousel('#blog',{
            interval:2000,
            touch:false,
            ride:true,
        })
    })
    concrete.getConcrete().then((mixes)=>{
        try {
            concrete.showLocation(mixes);
            concrete.showProduct(mixes);
            concrete.showAdmix(mixes);
        } catch (error) {
            console.log(error);
        }
        
        for (let i = 0; i < (Object.keys(mixes).length); i++) {
            const element = Object.keys(mixes)[i];
            // concrete.concreteCarousel(element)
        }
        ['#product','#admix','#location'].forEach(feature=>{
            try {
                const productCarousel = new Carousel(feature, {
                    interval: 2000,
                    touch: false,
                    ride:true
                  });
            } catch (error) {
                console.log(error);                
            }
        });
    })
    concrete.getBrand().then((brands=>{
        ui.showBrand(brands).then(()=>{
            try {
                const brandCarousel = new Carousel('#brand',{
                    interval:2000,
                    touch:false,
                    ride:false,
                });
                
            } catch (error) {
                console.log(error);                
            }
        });
    }));

    const nav = new Navigator();
    nav.footer();
    calculator.calculateLogic();
});