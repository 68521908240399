class Concrete{
    async getConcrete(){
        try {
            const  response = await fetch('/pages/concrete');
            const  data = await response.json();
            let admixes =  data.admixes
            let locations = data.locations;
            let products = data.products;
            admixes = admixes.map(item =>{
                return {
                    'subject':'โครงสร้างที่แตกต่าง',
                    'id':item.concrete_type_id,
                    'title':item.concrete_type_name,
                    'img':item.concrete_type_img,
                    'desc':item.concrete_type_desc,
                }
            })

            locations = locations.map(item=>{
                return {
                    'subject':'ส่งทั่วไทย',
                    'id':item.PROVINCE_CODE,
                    'title':item.PROVINCE_NAME,
                    'img':item.PROVINCE_NAME_ENG.toLowerCase()+'.jpg',
                    'desc':item.PROVINCE_NAME,

                }
            })

            products= products.map(item=>{
                return {
                    'subject':'คอนกรีตผสมเสร็จ',
                    'id':item.prod_id,
                    'title':item.prod_name,
                    'img':item.prod_img,
                    'desc':item.prod_desc,
                    'url':item.prod_url,
                }
            })
                return {admixes,locations,products}
        } catch (error) {
            console.log(error);            
        }
            
    }

    showAdmix(cons){
        const isAdmix = document.querySelector('#admix');
        const collectItems = isAdmix.querySelector('.carousel-inner')
        
        const locations = cons.locations
        const admixes = cons.admixes
        
        let per_page = 3;
        let page = 1;
        let totals = Math.ceil(admixes.length/per_page);
        
        let admixHtml = '';
        
        for (let x = 0; x < totals; x++) {
            admixHtml +=`
                <div class="row">
                <div class="carousel-item ${ x==0 ? 'active':''}">
                <div class="row">
                `; 
            for (let i = per_page*x; i < page*per_page; i++) {
                const admix = admixes[i];
                if (admix) {
                admixHtml += `
                    <div class="col-lg-4 col-md-4 col-sm-12 mb-2 d-flex align-items-stretch">
                        <div class="card">
                                <div class="d-flex flex-column justify-items-center align-items-center">
                                    <div class="mb-3 d-flex flex-column align-items-center">
                                        <a href="/concrete-mixes/${admix.id ? admix.id : 1}/${admix.title ? admix.title.replace(/[\s\.]/g,'-') : ''}">
                                            <canvas id="canvas_${admix.id ? admix.id : 1}" class="img-fluid rounded-circle mb-2"></canvas>
                                        </a>
                                        <div class="card-body"><h4>คอนกรีต${admix.title}<h4></div>
                                    </div>
                                
                                </div>
                        </div>
                    </div>                    
                                       
                `;
                }
            }
            admixHtml += `</div></div></div> `;
            page++;
            collectItems.innerHTML = admixHtml;
        }

        admixes.forEach(admix=>{
            let img = new Image();
            img.src = `/assets/images/contents/${admix.img}`;

            img.onload = function(){
            const canvas = document.getElementById(`canvas_${admix.id}`);
            const ctx = canvas.getContext('2d');
            canvas.width=512
            canvas.height=512
            ctx.drawImage(img, 0, 0, 512, 512);
            // document.querySelector(`#canvas_${admix.id}`).append(canvas);
            };
        })
    }
    imageDraw(){

    }
    showLocation(cons){
        const isAdmix = document.querySelector('#location');
        const collectItems = isAdmix.querySelector('.carousel-inner')
        const locations = cons.locations
        
               
        let per_page = 2;
        let page = 1;
        let totals = Math.ceil(locations.length/per_page);
        let locationHtml = '';
        
        for (let x = 0; x < totals; x++) {
            locationHtml +=`
                <div class="row">
                <div class="carousel-item ${ x==0 ? 'active':''}">
                <div class="row">
                `; 
            for (let i = per_page*x; i < page*per_page; i++) {
                const loc = locations[i];
                
                locationHtml += `
                    <div class="col-md-6 mb-2 d-flex align-items-stretch">
                        <div class="card">
                            
                                <div class="d-flex flex-column justify-items-center align-items-center">
                                    <div class="mb-3 d-flex flex-column align-items-center">
                                        <a href="/concretes/${loc.id}/${loc.title}">
                                            <canvas id="location_canvas_${loc.id}" class="img-fluid"></canvas>
                                        </a>
                                        <div class="card-body"><h4>คอนกรีต${loc.title}<h4></div>
                                    </div>
                                
                                </div>
                        </div>
                    </div>                    
                `;
            }
            locationHtml += `</div></div></div> `;
            page++;
            collectItems.innerHTML = locationHtml;
        }

        locations.forEach(loc=>{
            const  canvas = document.getElementById('location_canvas_'+loc.id);
            let img = new Image();
            const context = canvas.getContext('2d');
            try {
                if (context) {
                    img.onload = ()=>{
                    canvas.width = 512;
                    canvas.height = 412;
                    context.drawImage(img,0,0,512,412);
                }
                img.src="/assets/images/contents/"+loc.img;
            }
            } catch (error) {
                console.log(error);
            }
        })

    }

    showProduct(products){

        const showProduct = document.querySelector('#product > .carousel-inner')
        const indicatorElement = document.querySelector('#product > .carousel-indicators')
       
        const items = products.products;

        let indicatorHtml = '';
        let html = '';
        for (const product in items) {
            if (Object.hasOwnProperty.call(items, product)) {
                const item = items[product];
                
                indicatorHtml += `
                <button type="button" data-bs-target="#product" 
                     ${ product == 0 ? `class="active" aria-current="true"`:''} data-bs-slide-to="${product}" aria-label="Slide ${product}" >
                </button>
                `
                html += `
                <div class="carousel-item ${ product == 0 ? 'active':'' }">
                    <a href="/bangkok/c/${item.id}/${item.title.replace(/[\s\.]/g,'-')}">
                        <canvas id="product_canvas_${item.id}" 
                            style="min-height:350px; max-height:350px;"
                            class="img-fluid object-fit-cover w-100 h-100">
                        </canvas>
                    </a>
                    <div class="carousel-caption"><h5>${item.title}</h5></div
                    </div>
                </div>
                `
            }
            
            showProduct.innerHTML = html;
            indicatorElement.innerHTML = indicatorHtml;
        }

        items.forEach(product=>{
            const canvas = document.getElementById('product_canvas_'+product.id);
            const img = new Image();
            const context = canvas.getContext('2d');
            if (context) {
                try {
                    img.onload = ()=>{
                        canvas.width = 512;
                        canvas.height = 512;
                        context.drawImage(img,0,0,512,512);

                    }
                    img.src = '/assets/images/contents/'+product.img
                } catch (error) {
                    
                }
            }
        })

    }

    async getBrand(){
        let response = await fetch('/metros/api/allBrands');
        let json = await response.json();
        return json;
    }
}

export {Concrete}