class Blog{
    async getBlog(){
        const response = await fetch('/blogs/api/all');
        const blog= await response.json()
        return blog;
    }

    async showBlog(blogs){
        const isBlogs = document.querySelector('#blog');
        const collects = isBlogs.querySelector('.carousel-inner');
        
        let html = '';
        
        for (const item in blogs) {
            if (Object.hasOwnProperty.call(blogs, item)) {
                const blog = blogs[item];
                
                html += `
                <div class="carousel-item ${item == 0 ? 'active' : ''}">
                    <img 
                    src="/assets/images/contents/${blog.blog_images ? blog.blog_images : 'na.jpg'}" 
                    alt="${blog.blog_title}" 
                    style="min-height:350px; max-height:350px;"
                    class="img-fluid object-fit-cover w-auto h-100">
                    <div class="carousel-caption">
                        <h5 class="card-title">${blog.blog_title}</h5>
                        <p class="card-text">${blog.blog_article.slice(0, 50).replace(/<[^>]*>?/gm, '')}...</p>
                        <a 
                            class="card-link" 
                            href="/blogs/${blog.blog_url}/${blog.blog_title}">
                            &raquo; อ่าน ${blog.blog_title}
                        </a>
                    </div>
                </div>
              
                `
            }
            collects.innerHTML = html;
        }
        // <canvas id="blog_canvas_${blog.blog_article_id}" height="280" class="img-fluid"></canvas>
        // blogs.forEach(blog=>{
        //     let canvas = document.getElementById('blog_canvas_'+blog.blog_article_id)
        //     const ctx = canvas.getContext("2d");
        //     const img = new Image();
        //     try {
                
        //         let img = new Image();
        //         img.src = `/assets/images/contents/${admix.img}`;

        //         img.onload = function(){
        //         const canvas = document.getElementById(`blog_canvas_${blog.blog_article_id}`);
        //         const ctx = canvas.getContext('2d');
        //         canvas.width=380
        //         canvas.height=345
        //         ctx.drawImage(img, 0, 0, 380, 345);
        //         }
        //         img.src = "/assets/images/contents/"+blog.blog_images;
        //     } catch (error) {
        //         console.log(error);
        //     }
            
        // })
    }
}

export {Blog}