class UI{
    async showMainBanner(isBanner){

        await fetch('pages/banner').then(responsive=>responsive.json())
            .then(banners=>{
                
                const bannerElement = document.querySelector(isBanner);
                const indicator = bannerElement.querySelector('.carousel-indicators')
                
                let bannerHtml = ''
                let indicatorHtml = ''

                banners.forEach((banner,i) => {
                    indicatorHtml +=  `
                        <button type="button" 
                            data-bs-target="${isBanner}" data-bs-slide-to="${i}" aria-label="Slide ${i}"
                            ${ i === 0 ? ` class="active"  aria-current="true" `:''}>
                        </button>
                    `; 

                    bannerHtml +=`
                    <div class="carousel-item ${i == 0 ? 'active':''}">
                        <a href="${ banner.banner_url }" >
                            <div class="slick-title carousel-caption d-none d-md-block"><h2>${ banner.banner_title }</h2>
                            <p>
                                ${banner.banner_desc}
                                ${banner.photographer ? `<i class="photo">เอื้อเฟื้อภาพโดย คุณ${banner.photographer}</i>`:''}
                            </p>
                            </div>
                            <div class="">
                            <img 
                                src="/assets/images/contents/${banner.banner_pics}"  
                                alt="${banner.banner_title}" loading="lazy" 
                                style="min-height:250px;"
                                class="w-auto h-100 img-fluid object-fit-cover" />
                            </div>
                        </a>
                    </div>
                    `
                    indicator.innerHTML = indicatorHtml;
                    bannerElement.querySelector('.carousel-inner').innerHTML = bannerHtml;
                });
            })
    }

    async showBrand(brands){
        const isBrands = document.querySelector('#brand')
        const collects = isBrands.querySelector('.carousel-inner')
        
        let html = '';
        let totals = Math.ceil(brands.length/4);
        let per_page = 4;
        let page = 1;
        // let start = per_page*(page-1);
        
        for (let x = 0 ; x < totals ; x++) {
             html += `
                    <div class="carousel-item ${x == 0 ? 'active' : ''}">
                    <div class="row">
             `
            for (let i = per_page*x; i < page*per_page; i++) {
                html += `
                <div class="col-lg-3 col-md-3 col-sm-12 mb-4">
                    <div class="card ">
                        <div class="h-100 d-flex align-items-center justify-content-center pt-4">
                            <div class="brandlogo">
                                <img 
                                    class="img-fluid px-2 object-fit-cover" loading="lazy" 
                                    src="/assets/images/contents/${brands?.[i]?.brand_logo ?? 'na.jpg'}" 
                                    alt="${brands?.[i]?.brand_name ?? 'Unknown Brand'}" />
                            </div>
                        </div>
                        <div class="card-body">                
                            <a 
                                href="/brand/${brands?.[i]?.brand_id ?? '#'}" 
                                title="${brands?.[i]?.brand_name ?? 'Unknown Brand'}">
                                ${brands?.[i]?.brand_name ?? 'No Brand Name'}
                            </a>
                        </div>
                    </div>
                </div>
                
            `
            }
            page++;
            html +=`
                    </div>
                    </div>`
        }
        collects.innerHTML = html;
        
    }
}

export {UI}